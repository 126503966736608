  .grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* set the initial grid layout with 4 equal columns */
    gap: 10px;
    margin-top: 10px;
    margin-left: 20px;
    padding: 10px;
  }
  .card{
    width: 100%;
    height: auto;
  }
  
  /* adjust the grid layout for smaller screens */
  @media (max-width: 800px) {
    .grid {
      grid-template-columns: repeat(2, 1fr); /* use 2 columns for screens smaller than 768px */
    }
    .card{
      width: 100%;
      height: auto;
    }
    
  }
  
  /* adjust the grid layout for even smaller screens */
  @media (max-width: 480px) {
    .grid {
      grid-template-columns: 1fr; /* use 1 column for screens smaller than 480px */
    }
  }
  
  @media (max-width:1200px) {
    .-mt-7{
      margin-top: 30px;
    }
  }


/* *********Poppup***** */
.main{
  background-color: azure;
  height: 300px;
  width: 30%;
  position: absolute;
  top: 35%;
  left: 30%;
  z-index: 1;
  border-radius:20px ;
  -webkit-box-shadow: 0px 0px 26px -2px rgba(0,0,0,1);
-moz-box-shadow: 0px 0px 26px -2px rgba(0,0,0,1);
box-shadow: 0px 0px 26px -2px rgba(0,0,0,1);
}
.popup{
  height: 300px;
  width: 100%;
  top: 25%;
  right: 25%;
  border-radius:20px ;
}
.header{
  display: flex;
  justify-content: flex-end;
  padding: 0 30px 0 15px;
  cursor: pointer;
}